





























import Vue from 'vue'

import Modal from '../../components/Modal.vue'
import Input from '../../components/BaseInputVuelidate.vue'
import { messages } from '@/i18n/messages'
import { required } from 'vuelidate/lib/validators'

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default Vue.extend({
  name: 'CustomModal',
  components: { Modal, Input },
  data () {
    return {
      nameField: ''
    }
  },
  computed: {
    ...mapState('interests', [
      'name',
      'errors'
    ]),
    ...mapGetters('interests', ['getError'])
  },
  validations: {
    nameField: {
      required
    }
  },
  i18n: {
    messages
  },
  methods: {
    ...mapMutations('interests', ['setStateField']),
    ...mapActions('interests', ['createCollection']),
    beforeClose () {
      this.nameField = ''
      this.$v.$reset()
      this.setStateField({ field: 'name', value: '' })
    },
    createProject (data) {
      this.$emit('createProject', data)
    },
    changeValue (event) {
      this.setStateField({ field: 'name', value: event.value })
      this.nameField = event.value
    }
  }
})
