




















































import Vue from 'vue'
import BaseInput from '../../components/BaseInputVuelidate.vue'
import { mapState, mapActions } from 'vuex'
export default Vue.extend({
  name: 'InterestsManageHeader',
  components: { BaseInput },
  props: {
    displayState: {
      type: String,
      validator: (value) => {
        return ['creating', 'projects_operation'].indexOf(value) !== -1
      },
      default: 'creating'
    }
  },
  computed: {
    ...mapState('interests', ['selectedProjects'])
  },
  methods: {
    ...mapActions('interests', ['mergeCollections']),
    searching (acc) {
      this.$emit('search', acc)
    },
    createModal () {
      this.$emit('create-modal')
    },
    clearChooseProjects () {
      this.$emit('clear-choose-projects')
    },
    deleteGroup () {
      this.$emit('delete-group')
    },
    merging () {
      this.$emit('merge-projects-modal')
    }
  }
})
