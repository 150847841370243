

















import Vue from 'vue'

import Modal from '../../components/Modal.vue'
import { mapActions } from 'vuex'

export default Vue.extend({
  name: 'interestDeleteProject',
  components: { Modal },
  props: {
    projectId: {
      type: Number
    }
  },
  methods: {
    ...mapActions('interests', ['deleteCollection', 'getCollections']),
    deleteProject () {
      this.$emit('delete-project', this.projectId)
    }
  }
})
