


























import Vue from 'vue'

import Modal from '../../components/Modal.vue'
import Input from '../../components/BaseInputVuelidate.vue'
import { mapGetters, mapMutations } from 'vuex'
import { messages } from '@/i18n/messages'
import { required } from 'vuelidate/lib/validators'

export default Vue.extend({
  name: 'interestCloneProject',
  components: { Modal, Input },
  data () {
    return {
      name: ''
    }
  },
  validations: {
    name: {
      required
    }
  },
  i18n: {
    messages
  },
  props: {
    projectId: {
      type: Number
    }
  },
  computed: {
    ...mapGetters('interests', ['getError'])
  },
  methods: {
    ...mapMutations('interests', ['setStateField']),
    beforeClose () {
      this.name = ''
      this.$v.$reset()
      this.setStateField({ field: 'name', value: null })
    },
    cloneCollection () {
      this.$emit('clone-collection', { id: this.projectId, name: this.name })
    },
    changeValue (event) {
      this.setStateField({ field: 'name', value: event.value })
      this.name = event.value
    }
  }
})
