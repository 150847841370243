















































































































import Vue from 'vue'
import InterestsProjectCreatingModal from '../components/interests/InterestsProjectCreatingModal.vue'
import InterestsProjectDeletingConfirm from '../components/interests/InterestsProjectDeletingConfirm.vue'
import InterestsProjectCloningModal from '../components/interests/InterestsProjectCloningModal.vue'
import InterestsProjectMergingModal from '../components/interests/InterestsProjectMergingModal.vue'
import InterestsManageHeader from '../components/interests/InterestsManageHeader.vue'
import InterestsManageProjectItem from '../components/interests/InterestsManageProjectItem.vue'
import Pagination from '../components/BaseComponents/BasePagination/BasePagination.vue'
import VSelect from '../components/BaseComponents/VSelect/VSelect.vue'
import BaseCheckbox from '../components/BaseCheckbox.vue'
import _ from 'lodash'
// import InterestsService from '@/services/InterestsService.ts'
import BasePagePreloader from '../components/BasePagePreloader.vue'

import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
export default Vue.extend({
  data () {
    return {
      // for preloader
      loading: true,
      // saving page after request
      activePage: 1,
      // saving per_page after request
      perPage: 10,
      // for operation on one project
      operatedProjectId: null,
      // applied sort
      activeSort: null,
      activeSortDirection: null,
      // sort parameters
      sort: null,
      // sort direction (name)
      name_direction: null,
      // sort direction (audience_count)
      interests_audiences_count_direction: null,
      // for searching (watcher)
      searchQuery: ''
    }
  },
  components: {
    InterestsProjectCreatingModal,
    InterestsProjectDeletingConfirm,
    InterestsProjectCloningModal,
    InterestsProjectMergingModal,
    VSelect,
    BaseCheckbox,
    InterestsManageHeader,
    Pagination,
    InterestsManageProjectItem,
    BasePagePreloader
  },
  watch: {
    searchQuery: _.debounce(function (v) {
      this.getCollections({ per_page: this.perPage, page: this.activePage, q: v.value })
    }, 700)
  },
  methods: {
    ...mapActions('interests', ['getCollections', 'createCollection', 'mergeCollections', 'deleteCollection', 'cloneCollection']),
    ...mapMutations('interests', ['setStateField', 'addToSelectedProjects', 'deleteFromSelectedProjects']),
    // sort functions
    allSelected (acc) {
      acc
        ? this.projects.data.forEach(item => {
          this.addToSelectedProjects(item.id)
        })
        : this.setStateField({ field: 'selectedProjects', value: [] })
    },
    sortByName () {
      this.name_direction = this.name_direction === 'desc' ? 'asc' : 'desc'
      this.activeSort = 'name'
      this.activeSortDirection = this.name_direction
      this.getCollections({
        per_page: this.perPage,
        page: 1,
        sort: 'name',
        direction: this.name_direction
      })
    },
    sortByInterestsQuantity () {
      this.interests_audiences_count_direction = this.interests_audiences_count_direction === 'desc' ? 'asc' : 'desc'
      this.activeSort = 'interests_audiences_count'
      this.activeSortDirection = this.interests_audiences_count_direction
      this.getCollections({
        per_page: this.perPage,
        page: 1,
        sort: 'interests_audiences_count',
        direction: this.interests_audiences_count_direction
      })
    },
    // navigation functions
    getData (page) {
      this.activePage = page
      this.getCollections({
        per_page: this.perPage,
        page: page,
        sort: this.activeSort,
        direction: this.activeSortDirection
      })
    },
    changePerPage (acc, page) {
      this.perPage = acc
      this.getCollections({ per_page: acc, page: page })
    },
    // open modal functions
    openCloneProjectModal (acc) {
      this.operatedProjectId = acc
      this.$modal.show('interest-clone-project')
    },
    confirmDeleting (acc) {
      this.operatedProjectId = acc
      this.$modal.show('interest-delete-project')
    },
    // operation functions
    createProject () {
      this.createCollection()
        .then(() => {
          return new Promise((resolve, reject) => {
            this.getCollections({ per_page: this.perPage, page: 1 })
              .then((res) => {
                this.$modal.hide('interest-creating-project')
                resolve(res.data.data.data)
              })
          }).then((res) => {
            this.$router.push(res[0].id + '/explore/search')
          })
        })
    },
    cloneProject (acc) {
      this.cloneCollection(acc)
        .then(() => {
          this.getCollections({ per_page: this.perPage, page: 1 }).then(() => {
            this.$modal.hide('interest-clone-project')
          })
        })
        .catch((error) => {
          console.log(error)
        })
    },
    mergeProjects () {
      this.mergeCollections()
        .then(() => {
          this.$modal.hide('interest-merging-project')
          this.getCollections({ per_page: this.perPage, page: 1 })
          this.setStateField({ field: 'name', value: null })
          this.setStateField({ field: 'selectedProjects', value: [] })
        })
    },
    deleteProject (acc) {
      const d = acc ? [acc] : null
      this.deleteCollection(d)
        .then(() => {
          this.$modal.hide('interest-delete-project')
          this.getCollections({ per_page: this.perPage, page: this.activePage })
          if (!d) {
            this.setStateField({ field: 'selectedProjects', value: [] })
          } else {
            this.deleteFromSelectedProjects(acc)
          }
          this.operatedProjectId = null
        })
        .catch(() => {
          this.$modal.hide('interest-delete-project')
          this.$toaster.error('Something went wrong')
        })
    }
  },
  computed: {
    ...mapGetters(['get_width']),
    ...mapState('interests', ['interestsCollection', 'projects', 'selectedProjects']),
    width () {
      return Number(window.innerWidth)
    },
    dragOptions () {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    },
    displayState () {
      return this.selectedProjects.length <= 1 ? 'creating' : 'projects_operation'
    },
    interestsCollectionsIds () {
      const arr = []
      this.projects.data.forEach(item => {
        arr.push(item.id)
      })
      return arr
    }
  },
  mounted () {
    this.getCollections({ per_page: this.perPage, page: 1 })
      .then(() => {
        this.loading = false
      })
  },
  destroyed () {
    this.setStateField({ field: 'selectedProjects', value: [] })
  }
})
