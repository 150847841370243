
































import Vue from 'vue'

import BaseCheckbox from '../BaseCheckbox.vue'

export default Vue.extend({
  name: 'InterestsManageProjectItem',
  props: {
    id: {
      type: Number
    },
    name: {
      type: String,
      default: 'Empty name'
    },
    checked: {
      type: Boolean
    },
    quantity: {
      type: Number
    }
  },
  components: { BaseCheckbox },
  methods: {
    toggleProjectItem (acc) {
      acc
        ? this.$emit('delete-project-item', this.id)
        : this.$emit('add-project-item', this.id)
    },
    createModal () {
      this.$modal.show('interest-creating-project')
    },
    confirmDelete () {
      this.$emit('delete-project', this.id)
    },
    cloningProject () {
      this.$emit('clone-project', this.id)
    }
  }
})
